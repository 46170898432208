import React from "react";

import PageTitle from "../components/elements/PageTitle";
import Header from "../components/sections/Header";
import PortfolioSection from "../components/sections/PortfolioSection";
import Footer from "../components/sections/Footer";

const indexPage = () => (
  <div className="bg-white">
    <Header />
    <PageTitle pageTitle={"Portfolio"} description={``} />
    <PortfolioSection
      title={"Our Portfolio"}
      issuesId={
        "9064,17880,8835,8832,8836,8837,16666,8824,16665,8843,9063,8865,8818,16661,16657,17871,8839,16668,8813,16662,8810,8809,9907,8840,16660,8817,8815,8829,8828,8819,8827,8826,8825,8823"
      }
      sourceChangedTitle={{ 8832: "Watch now " }}
      page
    />
    <Footer />
  </div>
);

export default indexPage;
